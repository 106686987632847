import { default as abuseReports1g7BNNtRztMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93JALUEX1FXrMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexgoxWAHm6YrMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/admin/index.vue?macro=true";
import { default as moderationqQvTpoPnANMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/admin/moderation.vue?macro=true";
import { default as pendingBansGjVNtZmLm3Meta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/admin/pendingBans.vue?macro=true";
import { default as profilesbWxhx3Vyv4Meta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/admin/profiles.vue?macro=true";
import { default as expensesem2QRakKgSMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexnxIxVtGnb1Meta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewO45jx3xRDqMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitingAQm6W4vdYsMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/admin/translations/awaiting.vue?macro=true";
import { default as missing3MzzfIfq0OMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/admin/translations/missing.vue?macro=true";
import { default as userse11neQ3oeeMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/admin/users.vue?macro=true";
import { default as apiTznevpJnskMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/api.vue?macro=true";
import { default as _91slug_93tOjxC20cUlMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/blog/[slug].vue?macro=true";
import { default as indexSMVPfw8QRnMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93CxX1qgdrBaMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93SVvou7BRW4Meta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminHXLmAfNSXRMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/census/admin.vue?macro=true";
import { default as indexp1Q3NHdrALMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/census/index.vue?macro=true";
import { default as contact6QWZRrW8YfMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/contact.vue?macro=true";
import { default as designtOld9EdST0Meta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/design.vue?macro=true";
import { default as englishl62xfX1YKbMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/english.vue?macro=true";
import { default as faqqSqSVxHEhWMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/faq.vue?macro=true";
import { default as inclusiveMlV7rUhRy2Meta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/inclusive.vue?macro=true";
import { default as indexMLxZL2VQFOMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/index.vue?macro=true";
import { default as licensejnfthoZHBsMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/license.vue?macro=true";
import { default as academicgETfPoBoVIMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/links/academic.vue?macro=true";
import { default as indexhb6GfQDeLhMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/links/index.vue?macro=true";
import { default as mediaiUCmuqK0lEMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/links/media.vue?macro=true";
import { default as translinguisticsFh65ai8uMhMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/links/translinguistics.vue?macro=true";
import { default as zineDjBpVT1iQHMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/links/zine.vue?macro=true";
import { default as namesnVDfeEVuz8Meta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/names.vue?macro=true";
import { default as indexW6hxYucUjKMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/nouns/index.vue?macro=true";
import { default as templatesv0c5ogfdsSMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/nouns/templates.vue?macro=true";
import { default as peopleo9yEob4KEYMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/people.vue?macro=true";
import { default as privacylAytlEE6aFMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/privacy.vue?macro=true";
import { default as _91username_9364e5YMq3iAMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_932qrk41gfNGMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/profile/card-[username].vue?macro=true";
import { default as editorqYucThvfa1Meta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/profile/editor.vue?macro=true";
import { default as anyELGOnrKmRnMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/pronouns/any.vue?macro=true";
import { default as askLrvvTV8QaKMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/pronouns/ask.vue?macro=true";
import { default as avoidinggWmpAVkhLEMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/pronouns/avoiding.vue?macro=true";
import { default as indexnZ2ep3F62ZMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/pronouns/index.vue?macro=true";
import { default as mirrorqUPVDKg4qVMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/pronouns/mirror.vue?macro=true";
import { default as pronounZfkhHuUszhMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/pronouns/pronoun.vue?macro=true";
import { default as sourcesrXSGSsH1cXMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/sources.vue?macro=true";
import { default as teamKv8iUpvIw5Meta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/team.vue?macro=true";
import { default as terminologyBuvNMy8olSMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/terminology.vue?macro=true";
import { default as termsmrV8b7eAaHMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/terms.vue?macro=true";
import { default as userk9iOhLDXUKMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/user.vue?macro=true";
import { default as workshopsPEN6wDGduWMeta } from "/home/admin/www/pronombr.es/release/20241214180020/pages/workshops.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReports1g7BNNtRztMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansGjVNtZmLm3Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apiTznevpJnskMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93tOjxC20cUlMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexSMVPfw8QRnMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93CxX1qgdrBaMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93SVvou7BRW4Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminHXLmAfNSXRMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexp1Q3NHdrALMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contact6QWZRrW8YfMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishl62xfX1YKbMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqqSqSVxHEhWMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusiveMlV7rUhRy2Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicgETfPoBoVIMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexhb6GfQDeLhMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaiUCmuqK0lEMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsFh65ai8uMhMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zineDjBpVT1iQHMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesnVDfeEVuz8Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexW6hxYucUjKMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templatesv0c5ogfdsSMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleo9yEob4KEYMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacylAytlEE6aFMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_9364e5YMq3iAMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_932qrk41gfNGMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorqYucThvfa1Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyELGOnrKmRnMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askLrvvTV8QaKMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidinggWmpAVkhLEMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexnZ2ep3F62ZMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorqUPVDKg4qVMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounZfkhHuUszhMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sourcesrXSGSsH1cXMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teamKv8iUpvIw5Meta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyBuvNMy8olSMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsmrV8b7eAaHMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: userk9iOhLDXUKMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsPEN6wDGduWMeta || {},
    component: () => import("/home/admin/www/pronombr.es/release/20241214180020/pages/workshops.vue")
  }
]